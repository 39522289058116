/* jshint esversion: 6 */

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import Modal from 'react-bootstrap/Modal';

export const withSimpleModal = (WrappedComponent) => ({modalTitle, visible, onclose, dialogClassName, modalProps, ...props }) => {
    return (<Modal key={WrappedComponent.name} show={visible} {...modalProps} dialogClassName={`${WrappedComponent.name}-dialog ${dialogClassName ? dialogClassName : ""}`}>
        <Modal.Header closeButton={false} closeLabel='close'>
            <Modal.Title>
                {modalTitle}
                <span aria-label="Close button" className="customClose" ><FontAwesomeIcon icon={faTimesCircle} onClick={onclose} aria-label="Close button" role="button" /></span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <WrappedComponent {...props} onclose={onclose}/>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
    </Modal>)
};