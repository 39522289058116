import React, { useState, useEffect } from "react";

const localCache = {};

const nullIcon = () => null;

export const isNullIcon = icon => !icon || icon == nullIcon || icon?.type?.name == nullIcon?.name
  
const cachedFetch = (src, transform) => localCache[src] ? Promise.resolve(localCache[src]) :
    fetch(src)
        .then((response) => {
            return response.text();
        })
        .then(transform)
        .then(text => localCache[src] = text)
        .then(text => {console.debug("keys", Object.keys(localCache)); return text;});

export const ToComp = (src, props) => {
    const [root, setRoot] = useState();
    useEffect(() => {
        const fetchData = async () => {
            cachedFetch(src, content => {
                const doc = new DOMParser().parseFromString(content, "text/html");
                return doc.querySelector("svg");
            }).then(setRoot);
        };
        fetchData();
    }, [src]);
    const toReact = (element, extraProps) => {
        const toAttr = (attrs, attr) => {
            if (attr.name?.includes(':')) {
                // filter out xml ns
                return attrs;
            }
            // make attrs react compatible
            const name = (attr.name === "class" ? "className" : attr.name).replace(/-([a-z])/g, (match, capture) => capture.toUpperCase());
            return { ...attrs, ...{ [name]: `${attrs[name] ? attrs[name] + ' ' : ''}${attr.value}` } };
        };
        const props = Array.from(element.attributes).reduce(toAttr, typeof (extraProps) === "object" ? { ...extraProps } : { key: extraProps });
        return React.createElement(element.tagName, props, Array.from(element.hasChildNodes ? element.childNodes : []).filter(el => el.nodeType == 1).map(toReact));
    };
    if (root) {
        return toReact(root, props);
    } else {
        return null;
    }
};

export const configureIcons = (Icons, configuration) => 
{ 
    if (!Icons.$$configured) {
        const config_key = Object.keys(configuration).join("_");
        console.debug("Configuring Icons", config_key);
        Object.entries(configuration || {}).forEach(([feature, config]) => {
            if (!Icons[feature]) {
                console.warn(feature, "is not a supported feature")
                return
            }
            let iconOverride;
            if (config?.disabled) {
                iconOverride = nullIcon;
            } else if (config?.icon) {
                if (typeof config.icon === 'string') {
                    iconOverride = ToComp.bind(null, config.icon)
                } else if (typeof config.icon === 'object') {
                    iconOverride = Object.fromEntries(Object.entries(config.icon).map(([k, v]) => [k, ToComp.bind(null, v)]))
                } else {
                    console.warn("Unsupport icon config", config.icon, "for", feature);
                }
            }

            if (iconOverride) {
                if (typeof Icons[feature] === 'object' && !Icons[feature]?.$$typeof) {
                    // if feature calls for multiples but only one icon is available, use it for all
                    if (typeof iconOverride === 'function') {
                        Icons[feature] = Object.fromEntries(Object.keys(Icons[feature]).map(i => [i, iconOverride]));
                    } else {
                        Object.assign(Icons[feature], iconOverride);
                    }
                } else {
                    Icons[feature] = iconOverride;
                }
            }
        })
        Icons.$$configured = true;
    }
};
