

let performances; 


const filter = (p) => {
    if (localStorage.getItem("availfilter")) {
        Object.keys(p).forEach(k => delete p[k].availability);
    }
    return p;
};

const performancePromise = fetch('/performances.json').then(response => response.json()).then(p => performances = filter(p));

export const loadPerformances = () => performancePromise;
export const getPerformances = () => performances;
export const getPerformance = (id) => performances[id] || {};