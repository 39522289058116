interface TimeSync {
    camera: string;
    timeDelta: number;
    lastAdjusted: number;
}
export const mostRecentSyncs: {
    [name: string]: TimeSync;
} = {};
export let eldestSync: TimeSync = {
    camera: "",
    timeDelta: -1,
    lastAdjusted: -1,
};
export let lastDecision: string = "";

export const adjustTime = (
    camera: string,
    videoTime: number,
    epoch: number,
    now?: number,
): number => {
    if (!now) {
        now = Date.now();
    }
    const timeDelta = now - (videoTime*1000) - epoch;
    const thisSync = {
        camera,
        timeDelta,
        lastAdjusted: mostRecentSyncs[camera]?.lastAdjusted || 0,
    };
    mostRecentSyncs[camera] = thisSync;
    eldestSync =
        eldestSync.timeDelta > thisSync.timeDelta ? eldestSync : thisSync;
    const offsetMs = timeDelta - eldestSync.timeDelta;
    console.debug('cam offsetMs', camera, offsetMs);
    if (offsetMs < -300 && now - thisSync.lastAdjusted > 10000) {
        thisSync.lastAdjusted = now;
        lastDecision = `move ${camera} by ${offsetMs}`;
        // console.log(`move ${camera} by ${offsetMs}`);
        return offsetMs / 1000;
    } else {
        return 0;
    }
};
