import React, { useState, useEffect, createContext, useRef } from 'react';
import screenfull from 'screenfull'
import { getAccessibilityManager } from '../Accessibility/Accessibility';


export const PlayerContext = createContext();
let moveTimer;

const supportsHover = window.matchMedia("(hover)")["matches"];

export const PlayerContainer = ({ prefix, timeout, children, className, fullScreenHandler, accessibilityStyleManager, accessibilityConfig, languageConfig, ...props }) => {
    const dimensions = useViewport();
    const [moving, setMoving] = useState(false);
    const containerRef = useRef();
    prefix = prefix || '';
    const [ suspendRequests, setSuspendRequests ] = useState(undefined);
    const [ isFullScreen, setFullScreen ] = useState(false);
    const [ isHamburgerMenuOn, enableHamburgerMenu ] = useState(false);
    const [ keepActive, setKeepActive ] = useState(false);
    // clear timer when component unmounts
    useEffect(() => {
        setMoving(false);
        return () => {setMoving(false); clearTimeout(moveTimer);}
    }, []);
    // useEffect(() => () => {setMoving(false);clearTimeout(moveTimer);}, []);
    const onMove = () => {
        if (moveTimer) {
            clearTimeout(moveTimer);
        }
        moveTimer = setTimeout(setMoving.bind(null, false), 1000 * timeout);
        setMoving(true);

    };
    getAccessibilityManager(accessibilityStyleManager, accessibilityConfig ? () => Promise.resolve({ json: () => accessibilityConfig}) : undefined);
    const requestSuspend = (player) => {
        if (player.isPlaying() && suspendRequests === undefined) {
            console.debug("suspending");
            player.pause();
            setSuspendRequests(1);
        } else if (suspendRequests !== undefined) {
            console.debug(`incrementing suspendRequests to ${suspendRequests + 1}`);
            setSuspendRequests(suspendRequests + 1);
        }
    };

    const requestResume = (player) => {
        if (suspendRequests == 1) {
            console.debug("resuming");
            player.play();
            setSuspendRequests(undefined);
        } else if (suspendRequests !== undefined) {
            console.debug(`decrementing suspendRequests to ${suspendRequests - 1}`);
            setSuspendRequests(suspendRequests - 1);
        }
    };
    function toggleMobileSetting() {
        if(isHamburgerMenuOn) {
            enableHamburgerMenu(false);
        } else {
            enableHamburgerMenu(true);
        }
    }
    function toggleFullScreen() {
        try {
            if (screenfull.isEnabled) {
                if (screenfull.isFullscreen) {
                    screenfull.exit().then(() => setFullScreen(false));
                } else {
                    screenfull.request(containerRef.current).then(() => setFullScreen(true));
                }
            } else {
                // iphone: use native fullscreen
                const video = containerRef.current.querySelector("video#inplay_player");
                if (video.webkitEnterFullScreen) {
                    video.webkitEnterFullScreen();
                } else {
                    console.warn("Unsupported fullscreen request");
                }
            }
        } catch(err) {
            console.error(err);
        }
      }
    // create event listeners dynamically so that they can be disabled when e.g. accessibility panel is on, and don't use "hover" events on iOS
    // use onClick to activate on platforms that do not hover, i.e. iOS
    // it makes sense to group the onClick and onTouchStart for the mobile devices which don't support hover event
    const moveEventTypes = supportsHover ? ['onMouseMove'] : ['onClick', 'onTouchMove', 'onTouchStart'];
    // create listeners, do not enable if moving or keepActive is on
    const listeners = moveEventTypes.filter(() => !moving && !keepActive).reduce((listeners, eventType) => Object.assign(listeners, {[eventType]: onMove}), {});
    return (
        <div role="article" aria-label="player container" style={{
            position: 'relative'
        }} ref={containerRef} className={`${screenfull.isFullscreen ? 'fullscreen' : ''} ${className || ''}`} id={`${prefix}container`} {...listeners} {...props}>
            <PlayerContext.Provider value={{ accessibilityStyleManager, accessibilityConfig, languageConfig, prefix, dimensions, supportsHover, moving: moving || keepActive, toggleFullScreen: fullScreenHandler ? fullScreenHandler.event : toggleFullScreen, isHamburgerMenuOn, toggleMobileSetting, containerRef, requestResume, requestSuspend, isFullScreen: fullScreenHandler ? fullScreenHandler.status : isFullScreen, setKeepActive }}>
                {children}
            </PlayerContext.Provider>
        </div>
    );
};

const useViewport = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleWindowResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }

        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return { width, height };
}