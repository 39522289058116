import React, { Fragment, useState, useRef, useLayoutEffect, useEffect } from 'react';

const formatSeconds = (secs) => new Date(secs * 1000).toISOString().substr(12, 7);

export const Scenes = ({ thumbNails, shouldDisplay, listeners, sceneSeekContainer, time, top, left, duration, width, setCurrentTime, maxSceneHolderWidth, nominalSceneWidth }) => {
    const [baseScale, setBaseScale ] = useState(1);
    const [height, setHeight ] = useState(0);
    useEffect(() => {
        if (thumbNails.length) {
            const nominalWidthFactor = !isNaN(nominalSceneWidth) ? nominalSceneWidth / thumbNails[0].w : 1;
            const base_scale = nominalWidthFactor * thumbNails[0].w > maxSceneHolderWidth ? maxSceneHolderWidth / thumbNails[0].w : nominalWidthFactor;
            setBaseScale(base_scale);
            setHeight(base_scale * thumbNails[0].h);
        }
    }, [width, maxSceneHolderWidth, thumbNails]);

    return (
    <div
        ref={sceneSeekContainer}
        id="seekbar"
        style={{
            position: 'absolute',
            left: left,
            bottom: top,
            height: height,
            width: width,
            zIndex: 2
        }}
        {...listeners}
    >
        {shouldDisplay ? get_thumbs(thumbNails, time, maxSceneHolderWidth, width).map(({ tn, pos }) => (
            <Scene key={tn.startTime} baseScale={baseScale} 
            {...listeners}
            pos={pos} {...tn} left={left} time={time} duration={duration} width={width} setCurrentTime={setCurrentTime} />
        )) : null}
    </div>);
};

export const get_thumbs = (thumbNails, time, maxSceneHolderWidth, width) => {
    const positions = Array.from({length: 5}).map((_, i) => i - 2);
    const duration = thumbNails.length ? thumbNails[0].endTime - thumbNails[0].startTime : 0;
    return positions.flatMap(pos => {
        const this_time = time + pos * duration;
        return thumbNails.filter(tn => this_time >= tn.startTime && this_time < tn.endTime).map(tn => Object.assign({tn, pos}));
    });
}

const get_coords = (baseScale, w, midPointDistance, pos) => {
    const scale = Math.cos(Math.PI * midPointDistance) * baseScale;
    const aw = w * scale;
    const bs_w = baseScale * w;
    const dist_factor = pos === 0 ? 1 : Math.abs(Math.sin(Math.PI * midPointDistance / 2) / 2 / pos);
    const xdist = pos * bs_w * (dist_factor * (scale + 2)) + Math.sign(pos) * bs_w * midPointDistance +
        (pos > 0 ? bs_w - aw : 0);
    return { scale, aw, xdist };
};

export const scene_parms = ({ baseScale, startTime, endTime, pos, w, h, time, duration, width }) => {
    const timeRange = endTime - startTime;
    const relativeTime = ((time % timeRange) - ((timeRange - 1) / 2)) / (timeRange - 1);
    const linearDistance = pos === 0 ? 0 : pos - (Math.sign(pos) * .5) - relativeTime;
    const midPointDistance = Math.sign(pos) * linearDistance / 4;
    const { scale, aw, xdist } = get_coords(baseScale, w, midPointDistance, pos);
    const ah = h * scale;
    const xpos = Math.round(time * width / duration + xdist - baseScale * w / 2);
    return { timeRange, midPointDistance, scale, aw, xdist, ah, xpos, time, relativeTime, linearDistance };
}

const Scene = ({ baseScale, startTime, setCurrentTime, endTime, pos, w, h, url, x, y, time, duration, width, visible, left, transparent }) => {
    const { timeRange, midPointDistance, scale, aw, xdist, ah, xpos } = scene_parms({ baseScale, startTime, endTime, pos, w, h, url, time, duration, width });

    return (<div
        onClick={
            () => { setCurrentTime(startTime) }}
        style={{
            position: "absolute",
            border: "solid thin white",
            width: `${aw}px`,
            height: `${ah}px`,
            bottom: 0,
            opacity: .5 + scale / 2,
            zIndex: 100 + parseInt(100 * scale),
            left: `${xpos}px`,
            color: "orange",
            userSelect: "none",
            fontSize: `${parseInt(ah / 3)}px`,
            lineHeight: `${ah}px`,
            boxSizing: "content-box"
        }}>
        <div
            style={{
                backgroundImage: `url(${url})`,
                backgroundPositionX: `-${x}px`,
                backgroundPositionY: `${-y}px`,
                width: w,
                height: h,
                objectFit: 'none',
                transform: `scale(${scale})`,
                transformOrigin: 'top left',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 100 + parseInt(100 * scale) - 1,
            }}
        /><span
            style={{
                display: "inline-block",
                position: 'absolute',
                top: 0, left: 0,
                width: "100%",
                zIndex: 100 + parseInt(100 * scale),
            }}
        >{formatSeconds(startTime)}</span>
    </div>);
}