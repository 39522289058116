
export const promise_chain = () => {
    let chain = [];

    const nextOp = () => {
        const [op, resolve] = chain.length > 0 ? chain[0] : [undefined, undefined];
        if (op !== undefined) {
            op().then((val) => { resolve(val); chain.shift(); nextOp()});
        }
    }

    const addOp = (op) => {
        const p = new Promise((resolve, reject) => chain.push([op, resolve]));

        if (chain.length === 1) {
            nextOp();
        }
        return p;
    }
    return addOp;
};