/* jshint esversion: 6 */
'use strict';

const state_key = "in_player_acc";

export const wrap_acc_css = (references, cssRules) => 
    `
    ${references}
    span#caption_span:empty {
        display: none;
    }
    .in_captions, video::cue {
        empty-cells: hide;
        position: absolute;
        left: 50%;
        bottom: 10%;
        transform: translate(-50%, 10%);
        ${cssRules};
        }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     .in_captions {
        empty-cells: hide;
        position: absolute;
        left: 50%;
        bottom: 10%;
        transform: translate(-50%, 10%);
        ${cssRules};
        }
    }`;

const toCssRules = (state) => {
    const rules = state[state_key];
    const prefixMap = { "text": "", "window": "background-" };
    const colorRule = (k, prefix) => {
        return `${prefixMap[prefix]}color: rgba(${rules[k]},${rules[prefix + "-transparency"] || '1'})`
    }
    let references = "";

    const processRule = (rule) => {
        const kvsToCss = kvs => kvs.map(kv => kv.join(':')).join(';');
        
        if (typeof rule === 'string') {
            return rule;
        } else {
            // reference rule
            references += Object.values(rule).map(ref => Object.entries(ref).map(([key, value]) => `${key} { ${kvsToCss(Object.entries(value))}; }`)).join(' ');
            return kvsToCss(Object.keys(rule).flatMap(key => Object.values(rule[key]).flatMap(Object.entries).filter(kv => kv[0] === key)));
        }
    }

    const buildRule = (key) => 
        key.endsWith('color') ?
        colorRule.apply(null, [key].concat(key.split('-'))) :
        (key.endsWith('transparency') ? '' : processRule(rules[key]));

    const cssRules = Object.keys(rules).map(buildRule).join(';');
    return wrap_acc_css(references, cssRules);
};

class AccessibilityManager {
    constructor(config) {
        this.config = config;
        this.listeners = [];
        this.state = this.load();
    }

    get_defaults() {
        return this.config.reduce((acc, a) => {
            const key = a.key;
            const defaultOption = a.options.find(o => o.default);
            return defaultOption ? Object.assign(acc, { [key]: defaultOption.value }) : acc;
        }, {});
    }

    register(listener) {
        this.listeners.push(listener);
        return { [state_key]: this.state };
    }

    updateState(updates) {
        this.state = Object.assign({}, this.state, updates);
        this.save();
        this.listeners.forEach(l => l.setState({ [state_key]: this.state }));
    }

    resetState() {
        this.state = {};
        this.updateState(this.get_defaults());
    }

    load() {
        return Object.assign(this.get_defaults(), JSON.parse(localStorage.getItem(state_key)));
    }

    save() {
        localStorage.setItem(state_key, JSON.stringify(this.state));
    }
}

let accessibility_manager;

const inlineStyleManager = () => {
    const style = document.createElement('style');
    style.id = 'ccstyle';
    style.type = 'text/css';
    document.head.appendChild(style);
    return (css) => style.innerHTML = css;
};

const createAccessibilityManager = (styleManager, configLoader) => {
    const styleUpdater = (styleManager || inlineStyleManager)();
    const accessibilityListener = rules => styleUpdater(toCssRules(rules));
    return (configLoader ? configLoader() : fetch("accessibility.json")).then(response => response.json())
        .then(config => {   
            const amgr = new AccessibilityManager(config);
            accessibilityListener(amgr.register({setState: accessibilityListener}));
            return amgr;
        });
};

export const getAccessibilityManager = (styleManager, configLoader) => accessibility_manager = accessibility_manager || createAccessibilityManager(styleManager, configLoader);
