/* jshint esversion: 6 */


const translations = {
    'KOR': {
        'Restore to defaults':'기본값으로 복원',
        'Subtitle text preview':'자막 텍스트 미리보기',
        'starts in':'에서 시작',
        'second':'두번째',
        'seconds':'초',
        'watch now':'지금보기',
        'stay':'머무르다',
        'subtitles':'자막',
        'Off':'끄다',
        'Default':'기본',
        'default':'기본',
        'Accessibility':'접근성',
        'Audio And Subtitles':'오디오 및 자막',
        'Black':'검은 색',
        'Blue':'파란색',
        'Cyan':'시안',
        'Font Style':'글꼴 스타일',
        'Green':'녹색',
        'Red':'빨간색',
        'Restore Defaults':'기본값으로 복원',
        'Sample Subtitle Text':'샘플 자막 텍스트',
        'Setting':'환경',
        'Text Color':'텍스트 색상',
        'Text Size':'텍스트 크기',
        'Text transparency':'텍스트 투명도',
        'White':'하얀',
        'Window color':'창 색상',
        'Window transparency':'창 투명도',
        'Yellow':'노란색',
    },
    'SPA': {
        'Restore to defaults':'Restaurar a los valores predeterminados',
        'Subtitle text preview':'Vista previa de texto de subtítulos',
        'starts in': 'empieza en',
        'second': 'segundo',
        'seconds': 'segundos',
        'watch now': 'ver ahora',
        'stay': 'quedar',
        'subtitles': 'subtítulos',
        'Off': 'Apagado',
        'Default': 'Predeterminados',
        'default': 'predeterminados',
        'Accessibility': 'Accesibilidad',
        'Audio And Subtitles': 'Audio y Subtítulos',
        'Black': 'Negro',
        'Blue': 'Azul',
        'Cyan': 'Cyan',
        'Font Style': 'Estilo de fuente',
        'Green': 'Verde',
        'Red': 'Roja',
        'Restore Defaults': 'Restaurar predeterminados',
        'Sample Subtitle Text': 'Ejemplo de texto de subtítulo',
        'Setting': 'Configuración',
        'Text Color': 'Color de texto',
        'Text Size': 'Tamano del texto',
        'Text transparency': 'Transparencia de texto',
        'White': 'Blanco',
        'Window color': 'Color de la ventana',
        'Window transparency': 'Transparencia de ventana',
        'Yellow': 'Amarillo',
    }
};

const language_font_size = {
    'SPA': '75%',
}

export function get_language_font_size(language) {
    return language_font_size[language] || '100%';
}

export function translate_language (language, label) {
    return (language && translations[language] && translations[language][label]) || label;
}

const multi_part_re = /(.*)\s\(([^\)]+)\)/;
export function translate (language, label) {
    // check if multi part label
    const parts = label?.match(multi_part_re);
    return  (parts) ? `${translate_language(language, parts[1])} (${translate_language(language, parts[2])})` : translate_language(language, label);
}

