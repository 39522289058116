import astronicsPlayArrow from "./src/controller/Icon/astronics/regular/PauseCircle";
import astronicsAccessibilitySvg from "./src/controller/Icon/astronics/regular/UserCog";
import astronicsReplay from "./src/controller/Icon/astronics/duotone/Backward";
import astronicsForward from "./src/controller/Icon/astronics/duotone/Forward";
import astronicsPlayCircleFilled from "./src/controller/Icon/astronics/regular/PlayCircle";
import astronicsPauseCircleFilled from "./src/controller/Icon/astronics/regular/PauseCircle";

import play_arrow from "./src/controller/Icon/play.svg";
import DogLeashed from "./src/controller/Icon/ideanova/DogLeashed";

const astronicsConfig = {
  icons: {
    play_arrow: astronicsPlayArrow,
    Accessibility_svg: astronicsAccessibilitySvg,
    rewind_button: astronicsReplay,
    forward_button: astronicsForward,
    play_button: astronicsPlayCircleFilled,
    pause_button: astronicsPauseCircleFilled,
  },
};

const defaultConfig = {
  icons: {
    play_arrow: play_arrow,
    Accessibility_svg: DogLeashed,
  },
};

export default (() => {
  switch (process.env.REACT_APP_CONFIG) {
    case "astronics":
      return astronicsConfig;
    default:
      return defaultConfig;
  }
})();
