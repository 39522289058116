import React, {useState} from 'react';
import { getPerformances } from '../model/performances';
import { useHistory } from "react-router-dom";
import { get_query_param } from '../util/ticket_validator';

export const Home = (props) => {
    const performances = getPerformances();
    const history = useHistory();
    const gotoPerformance = (performance) => {
        history.push(`/performance/${performance.id}`);
        return null;
    };

    const [ refresh, setRefresh ]  = useState();


    const getParms = (performance) => {
        return is_available(performance) ? {
            className: "link",
            onClick: () => gotoPerformance(performance)
        } : {};
    };

    const urlPerformanceId = get_query_param('event_id');
    const urlPerformance = Object.values(performances).find(performance => performance.eventId === urlPerformanceId);

    return urlPerformance ? gotoPerformance(urlPerformance) : (<div className="App">
        <header className="App-header">
            <h1>Inconcert</h1>
        </header>
        <ul>
            <li><p>Welcome to Inconcert, a virtual concert experience. Please select your session below:</p></li>
            {Object.values(performances).filter(p => !p.hidden).map(performance => (
              <li key={performance.id} {...getParms(performance)}>{performance.title}<Availability {...performance} whenAvailable={setRefresh}/></li>
            ))}
        </ul>
    </div>);
};

const compute_availability = (availability) => {
    if (availability) {
        availability = new Date(availability);
        return (availability.getTime() - Date.now()) / (60 * 1000);
    }
}

export const is_available = ({availability}) => availability === undefined || compute_availability(availability) <= 0;

export const Availability = ({title, availability, whenAvailable }) => {
    const [ refresh, setRefresh ]  = useState();
    let when = "";
    let style = "";
    const duration_until = compute_availability(availability);
    if (duration_until) {
        if (duration_until <= 0) {
            when = " now available";
            style = "now";
            if (refresh !== undefined) {
                whenAvailable();
                setRefresh(undefined);
            }
        } else if (duration_until < 1) {
            when = ` in ${parseInt(duration_until * 60)} s`;
            setTimeout(() => setRefresh(duration_until), 1000);
            style = "verysoon";
        } else if (duration_until < 60 ) {
            when = ` in ${parseInt(duration_until)} mins`;
            setTimeout(() => setRefresh(duration_until), 30 * 1000);
            style = "soon";
        } else {
            availability = new Date(availability);
            when = ` at ${availability.toLocaleDateString()} ${availability.toLocaleTimeString()}`;
            setTimeout(() => setRefresh(duration_until, 30 * 1000))
        }
    }
    return (<span className={`availability ${style}`}>{when}</span>);
};