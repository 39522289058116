
import { get } from 'lodash-es';
import { config } from '../config.js';

const getDeviceId = () => {
    let deviceId = localStorage.getItem("deviceId");
    if (!deviceId) {
        var array = new Uint8Array(9);
        window.crypto.getRandomValues(array);
        deviceId = btoa(array);
        localStorage.setItem("deviceId", deviceId);
    }
    return deviceId;
};

const deviceId = getDeviceId();

export const get_query_param = (name) => {
    const value = (window.location.search.match(new RegExp(`${name}=([^&]+)`)) || [])[1];
    console.debug("qp", name, " is ", value);
    return value;
}

const lease_state = {
};

const lease_expiry = () => {
    if (lease_state.expires < Date.now()) {
        alert("Ticket is being used by someone else");
        window.location.reload();
    }
};

export const relinguish_lease = () => {
    clearInterval(lease_state.timer);
    lease_state.timer = undefined;
};

const schedule = (fn, at) => {
    console.log(fn, "scheduled at", new Date(Date.now() + at));
    return setTimeout(fn, at);
};

const watch_dog = (eventId, ticketNumber, sgn, data) => {
    relinguish_lease();
    Object.assign(lease_state, data, {eventId, ticketNumber});
    const lease_expires = (data.expires - Date.now());
    lease_state.timer = schedule(lease_expiry, lease_expires + 1000);
    schedule(() => validate_ticket(eventId, ticketNumber, sgn), lease_expires / 2);
    return data;
};

export const get_token = (eventId, ticketNumber) => lease_state.token;

let inflight_validator;

export const validate_ticket = (eventId, ticketNumber, sgn) => {
    console.log(new Date(), eventId, ticketNumber, inflight_validator);
    const ticketUrl = [config.ticketLeaseUrl, eventId, ticketNumber, deviceId, sgn].join('/');
    console.log(ticketUrl);
    if (inflight_validator === undefined) {
       inflight_validator = fetch(ticketUrl, {
        cache: "default",
        mode: "cors"
       }).then(resp => {
            schedule(() => inflight_validator = undefined, 10000);
            if (resp.status === 200) {
                return resp.json();
            } else if (resp.status === 404) {
                return {error: 'ticket_not_found'};
            } else if (resp.status === 409) {
                return { error: 'ticket in use '};
            }
        }, resp => resp ? {error: resp}: {}).then(json => watch_dog(eventId, ticketNumber, sgn, json));
    }
    return inflight_validator;
      
};

