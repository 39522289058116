// Internet Explorer 6-11
export const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
export const isEdge = !isIE && !!window.StyleMedia;

export const isFF = navigator && navigator.userAgent && (navigator.userAgent.toLowerCase().includes('firefox'));


export const to_ranges = (played, buffered, adBreaks) => {
    const adBreakWidth = isFF ? .7 : .4; // will revisit it later
    const baseRanges = [
        {
            from: 0,
            to: played,
            color: 'white'
        },
        {
            from: played,
            to: buffered,
            color: 'grey'
        },
        {
            from: buffered,
            to: 100,
            color: 'black'
        }
    ];

    const addAdBreak = ({ from, to, color }) => {
        // filter ad breaks that fall into the range
        const adBreaksInRange = adBreaks.filter(ab => (ab >= from && (ab+adBreakWidth) <= to) 
        || (ab <= from && (ab+adBreakWidth) > from) || (ab < to && (ab+adBreakWidth) >= to)).map(from => {
            return { from, to: Math.round((from + adBreakWidth) * 100) / 100, color: 'orange' }
        });
        return adBreaksInRange.reduce((accumulator, adBreak) => {
            // remove the last entry from accumulator
            const last = accumulator.pop();
            // concat to the remainder of the accumulator the last entry before adbreak, the ad break and last entry after ad break.

            // ALLA
            if(last.from >= adBreak.from && last.to <= adBreak.to) {
                return accumulator.concat([
                    {
                        from: adBreak.from,
                        to: adBreak.to,
                        color: adBreak.color
                    }]);
            } else if (last.from <= adBreak.from && last.to <= adBreak.to){ //LALA
                return accumulator.concat([
                    {
                        from: last.from,
                        to: adBreak.from,
                        color: last.color
                    },
                    {
                        from: adBreak.from,
                        to: adBreak.to,
                        color: adBreak.color
                    }]);
            } else if (last.from >= adBreak.from && last.to >= adBreak.to) { //ALAL
                return accumulator.concat([
                    {
                        from: adBreak.from,
                        to: adBreak.to,
                        color: adBreak.color
                    },
                    {
                        from: adBreak.to,
                        to: last.to,
                        color: last.color
                    }]);
            } else { //LAAL
                return accumulator.concat([
                    {
                        from: last.from,
                        to: adBreak.from,
                        color: last.color
                    },
                    {
                        from: adBreak.from,
                        to: adBreak.to,
                        color: adBreak.color
                    },
                    {
                        from: adBreak.to,
                        to: last.to,
                        color: last.color
                    }]);
            }
        }, [{ from, to, color }]);
    }
    //remove duplicated elements
    return baseRanges.flatMap(addAdBreak).filter((elem, i, array) => i == 0 || (elem.from !== array[i - 1].from && elem.to !== array[i - 1].to && elem.color !== array[i - 1].color))
};