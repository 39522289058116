import React, { useState, useEffect, createContext, useContext } from "react";
import Fab from "@material-ui/core/Fab";
import Replay30Icon from "@material-ui/icons/Replay30";
import Forward30Icon from "@material-ui/icons/Forward30";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import "./GiantButtons.scss";
import { PlayerContext } from "../container/PlayerContainer.js";
import config from "../../../config";
import { emitInshowEvent, Events } from 'inplayweb6-lib';
import { configureIcons, isNullIcon } from '../Icon/support';

const ie11 =
  navigator &&
  navigator.userAgent &&
  (navigator.userAgent.includes("Trident") ||
    navigator.userAgent.includes("Edge"));

const Icons = {
  rewind: Replay30Icon,
  control: {
    play: PlayCircleFilledIcon,
    pause: PauseCircleFilledIcon
  },
  forward: Forward30Icon
}

const GiantButtons = ({ player, configuration }) => {
  const { dimensions, moving } = useContext(PlayerContext);
  const [localHide, setLocalHide] = useState(false);
  if (configuration) {
    configureIcons(Icons, configuration);
  }
  const go = (amount) => () => { amount && amount > 0 ? emitInshowEvent(Events.FastForward) : emitInshowEvent(Events.Rewind); player.seekTo(player.getCurrentTime() + amount)};
  // ie 11 mitigations
  const removeFocus = (fn) => (e) => {
    e.target.blur();
    fn();
    if (ie11) {
      setLocalHide(true);
    }
  };

  if (localHide) {
    setTimeout(() => setLocalHide(false), 20);
  }
  return (
    <div
      //need more accurate left and top value
      className={`giant_buttons ${moving && !localHide ? "show" : "hidden"}`}
    >
      <Button
        id="rewind_button"
        Icon={Icons.rewind}
        ariaLabel="rewind 30 seconds button"
        onClick={removeFocus(go(-30))}
      />
      <Button
        Icon={player.isPlaying() ? Icons.control.pause : Icons.control.play}
        onClick={removeFocus(() => {
          if (player.isPlaying()) {
            player.pause();
            emitInshowEvent(Events.Pause);
          } else {
            player.play();
            emitInshowEvent(Events.Resume);
          }
        })
        }
        ariaLabel={player.isPlaying() ? "giantPauseButton" : "giantPlayButton"}
        id={player.isPlaying() ? "pause_button" : "play_button"}
      />
      <Button
        id="forward_button"
        Icon={Icons.forward}
        ariaLabel="forward 30 seconds button"
        onClick={removeFocus(go(30))}
      />
    </div>
  );
};
export default GiantButtons;

const Button = ({ id, Icon, onClick, ariaLabel }) => {
  const AltIcon = config.icons[id];
  return !isNullIcon(Icon) || !isNullIcon(AltIcon) ? (
    <Fab
      onClick={onClick}
      id={id}
      color="primary"
      size="large"
      aria-label={ariaLabel}
    >
      {AltIcon ? <AltIcon /> : <Icon />}
    </Fab>
  ) : null;
};
