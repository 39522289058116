import * as React from "react";

function SvgDogLeashed(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" {...props}>
      <path d="M416 112c-8.84 0-16 7.16-16 16s7.16 16 16 16 16-7.16 16-16-7.16-16-16-16zm120-48h-49.16l-.53-1.02C476.78 43.88 457.59 32 436.22 32H360c-.53 0-1.01.14-1.54.16l.14-.16-27.29-27.28C321.23-5.36 304 1.78 304 16.03v164.19L67.16 3.19C60.08-2.1 50.06-.65 44.77 6.42l-9.58 12.81c-5.29 7.08-3.85 17.11 3.23 22.4L239.59 192H184c-31.28 0-59.56 12.32-80.95 32H88c-22.06 0-40-17.94-40-40 0-13.25-10.75-24-24-24S0 170.75 0 184c0 43.01 31.04 78.76 71.88 86.37C67.03 283.39 64 297.31 64 312v168c0 17.64 14.34 32 32 32h80c17.66 0 32-14.36 32-32v-96h128v96c0 17.64 14.34 32 32 32h80c17.66 0 32-14.36 32-32V240h8c48.53 0 88-39.47 88-88v-48c0-22.06-17.94-40-40-40zM280 336H160v128h-48V312c0-39.7 32.31-72 72-72h96v96zm152 128h-48V336h-56v-93.27l104 26V464zm96-312c0 22.06-17.94 40-40 40h-56v27.27l-80-20V88c0-4.41 3.59-8 8-8h76.22c3.06 0 5.78 1.69 7.16 4.42L457.16 112H528v40z" />
    </svg>
  );
}

export default SvgDogLeashed;
