import React, { useEffect, useState } from 'react';

import { useParams } from "react-router-dom";
import { getTicketUrl, InConcert } from '../player/Inconcert';
import { getPerformance } from '../model/performances';
import { useStoredState } from '../util/useStoredState';
import { config } from '../config.js';
import { validate_ticket, relinguish_lease, get_query_param } from '../util/ticket_validator';
import { Availability, is_available } from './Home';

export const Performance = () => {
    const { id } = useParams();
    const performance = getPerformance(id);
    const [ wallet, setWallet ] = useStoredState('ticket-wallet', {}, sessionStorage);
    const [ ticket, setTicket ] = useState();
    const [ token, setToken ] = useState();
    const [ refresh, setRefresh ] = useState();
    const query_param_ticket = [ 'ticket_id', 'security_code' ].map(get_query_param).join('/') ;
    const sgn = get_query_param('sgn');
    if (wallet[id] !== query_param_ticket && query_param_ticket !== '/') {
        setWallet(Object.assign(wallet, { [id]: query_param_ticket }));
    }
    const validationPending = !performance.nonTicketed && ticket !== wallet[id];
    if (validationPending) {
        // ticket was stored, validate it, or passed it via query params
        validate_ticket(performance.eventId, wallet[id] || ticket, sgn).then(({token, error}) => {
            if (error) {
                alert(error);
            } else {
                setToken(token);
                setTicket(wallet[id]);
            }
        });
    }
    useEffect(() => performance.nonTicketed ? () => {} : relinguish_lease(performance.eventId, ticket), [performance.eventId]);
    const updateTicket = (ticket, token) => {
        setWallet(Object.assign(wallet, { [id]: ticket }));
        setTicket(ticket);
        setToken(token);
    };
    const PerformanceView = is_available(performance) ? InConcert : NotYet;
    return ticket || performance.nonTicketed ? (
        (<PerformanceView {...performance} ticket={ticket} token={token} whenAvailable={() => setRefresh(Date.now())}/>)
    ) : (validationPending ? null : <TicketCheck performance={performance} updateTicket={updateTicket} />);
};

const TicketCheck = ({ performance, updateTicket }) => {
    const [ticket, setTicket] = useState('');
    const [token, setToken] = useState('');
    const [ticketStatus, setTicketStatus ] = useState();
    const submitTicket = e => {
        e.preventDefault();
        validate_ticket(performance.eventId, ticket).then(({token, error}) => {
            const ticketStatus = !error ? "Accepted" : "Rejected";
            setToken(token);
            setTicketStatus(ticketStatus);
        });
    };
    return (
        <div className="Performance">
            <div className={`ticket-check ${ticketStatus === 'Accepted' ? 'slide-out' : ''}${ticketStatus === 'Rejected' ? 'error' : ''}`} onAnimationEnd={() => ticketStatus === 'Accepted' && updateTicket(ticket, token)}>
                <h2>Ticket check</h2>
                <form onSubmit={submitTicket}>
                    <p>0
                        Please enter ticket for event "{performance.title}"
                    </p>
                    {ticketStatus === 'Rejected' ? (<p className="error">Ticket was rejected. Please enter a valid ticket</p>) : null}
                    <p>
                        <input type="text" value={ticket} onChange={({ target }) => setTicket(target.value.trim())} />
                        <input type="submit" value="Enter Ticket" />
                    </p>
                </form>
                <p>
                    If you do not yet have a ticket <button onClick={() => window.open(`${config.storeUrl}/${performance.storeId}`, 'ticket-shop')}>purchase ticket</button>
                </p>
            </div>
        </div>
    );
};


const NotYet = (performance) => {
    const [leave, setLeave] = useState();
    return (
        <div className="Performance">
            <div className={`ticket-check ${leave ? 'slide-out' : ''}`} onAnimationEnd={() => leave && performance.whenAvailable() }>
                <div>
                    <h2>Performance is not yet available</h2>
                    {performance.boxart ? <img alt={performance.title} src={`/performances/${performance.id}/${performance.boxart}`}/> : ''}
                    {performance.title} premiers <Availability {... performance} whenAvailable={() => setLeave(true)}/>
                </div>
            </div>
        </div>
    );
};
