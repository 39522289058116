/* jshint esversion: 6 */

import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { UnwrappedInplayPlayer, getInplayInstance } from 'inplayweb6-lib';
import { PlayerContainer, BottomControl, GiantButtons } from 'inplay-web-controls';
import 'inplay-web-controls/dist/inplay-web-controls.css';
import 'inplayweb6-lib/dist/inplayweb6.css';
import { config } from '../config.js';


import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { faThLarge, faChevronRight, faChevronLeft, faSpinner, faSyncAlt, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { min } from 'moment';
import { method, set } from 'lodash-es';
import { promise_chain } from '../util/promise_queue.js';
import { adjustTime } from "../util/TimeSync";

import useKeyboardShortcut from 'use-keyboard-shortcut'
import screenfull from '../util/fullscreen';


library.add(faThLarge);
let moveTimer;

export function generateVideoDetails(userAgent) {
    userAgent = userAgent.toLowerCase();
    let isDRM = true;

    const [device, proxyType] = (userAgent.indexOf("safari") !== -1 && userAgent.indexOf("chrome") === -1) ? ["FairPlay", "FPS"] : ["Widevine", "MWVOFF"] || ["WideVine", "MWVOFF"];

    const isSafari = device === "FairPlay";
    return {
        device,
        server: isDRM ? `${config.lsUrlTemplate}type=${proxyType}` : "",
        cert: isDRM && isSafari ? config.certPath : "",
    };
}

export const getKeyEntries = (manifest) => {
    return manifest.split("\n")
        .filter(line => line.match(/^#EXT-X-PROGRAM-DATE-TIME.*/))
        .map(line => line.replace(/^#EXT-X-PROGRAM-DATE-TIME:/, ''))
    [0];
};
export const fetchCameraEpoch = async (channel, camera) => {
    return fetch(`/performances/${channel}/${camera}/stream-720p.m3u8`)
    .then(response => response.text())
        .then(data => getKeyEntries(data));
}

const base_config = generateVideoDetails(window.navigator.userAgent);

const videos = [];

let videoSyncTimer;
const threshold = 0.250;
const videoSyncFreq = 250;
let seeking = false;
let setSyncHandle = () => {};
let seekAllQueue = Promise.resolve(true);


window.diff_main = 0;
window.diff_cam1 = 0;
window.last_live_sync = 0;

const seekAll = (seconds) => {
    setSyncHandle(seeking = true);
    seekAllQueue.then(() => {
        videos.forEach(v => v.current.pause());
        videos.forEach((v, i) => v.current.seekTo(parseFloat(seconds)));
        seekAllQueue = new Promise((resolve) => {
            const pollSeeking = () => {
                const anySeeking = videos.map(v => v.current.player.seeking).reduce((acc, val) => acc | val, false);
               // console.log(videos.map(v => JSON.stringify({seeking: v.current.player.seeking, time: v.current.getCurrentTime(), bufferedInfo: v.current.inplay.getBufferedInfo()})));
                if (anySeeking) {
                    // let it buffer for a bit before resolving
                    setTimeout(() => resolve(true), 500);
                } else {
                    // seek active, wait 100ms 
                    setTimeout(pollSeeking, 100);
                }
            };
            setTimeout(pollSeeking, 0);
        })
        .then(() => Promise.all(Array.from(videos).reverse().map(v => {
            try {
                return v.current.play();
            } catch (err) {
                console.error(err);
                return Promise.resolve(1);
            }
        })))
        .then(() => {
            setSyncHandle(seeking = false);
            seekAllQueue = Promise.resolve(true);
        });
    });
};


const liveVideoSync = () => {
    const main = document.querySelectorAll('video')[0];
    const cam1 = document.querySelectorAll('video')[1];

    if (main) {
        const doSomethingWithTheFrame = async (now, metadata) => {
            const keyParams = await fetchCameraEpoch("live-test1", "main");
            const referenceTime = new Date(keyParams);
            const current = videos[0].current;
                const timeUpdate = (e) => {
                    // console.log("--------------------------", keyParams);
                    // console.log("--------------------------", referenceTime);
                    // console.log("--------------------------", referenceTime.getTime());
                    // console.log("--------------------------", main.currentTime,);
                    const timeAdjustment = adjustTime(
                        "main",
                        main.currentTime,
                        referenceTime.getTime()
                    );
                    if (timeAdjustment && timeAdjustment != 0) {
                        console.log(`main current time before`, main.currentTime);
                        console.log(`main moved by ${timeAdjustment/2}`);                           
                        main.currentTime += timeAdjustment/2;
                        // current.seekTo(current.getCurrentTime() + 1000 * timeAdjustment/2);

                        console.log(`main current time after`, main.currentTime);

                    }
                };
                main.addEventListener("timeupdate", timeUpdate);
        };
        // Initially register the callback to be notified about the first frame.
        main.requestVideoFrameCallback(doSomethingWithTheFrame);
    }

    if (cam1) {
        const doSomethingWithTheFrame = async (now, metadata) => {
            const keyParams = await fetchCameraEpoch("live-test1", "cam1");
            const referenceTime = new Date(keyParams);
            const current = videos[1].current;

                const timeUpdate = (e) => {
                    const timeAdjustment = adjustTime(
                        "cam1",
                        cam1.currentTime,
                        referenceTime.getTime()
                    );
                    if (timeAdjustment && timeAdjustment != 0) {
                        // console.log(`cam1 current time before`, cam1.currentTime);
                        console.log(`cam1 moved by ${timeAdjustment/2}`);                                    
                        cam1.currentTime += timeAdjustment/2;
                        // current.seekTo(current.getCurrentTime() + 1000 * timeAdjustment/2);
                        // console.log(`cam1 current time after`, cam1.currentTime);
                    }
                };
                cam1.addEventListener("timeupdate", timeUpdate);
        };
        // Initially register the callback to be notified about the first frame.
        cam1.requestVideoFrameCallback(doSomethingWithTheFrame);
    }
}

const vod_sync_offsets = {
};

const videoSync = () => {
    let timeout_factor = 1;
    let outofsync = false;
    console.log("Syncing...");
    const get_offset = (video) => vod_sync_offsets[video.props.prefix.replace(/.*_/, "")] || 0;
    const getAdjustedTime = (video) => {
        const currTime = video.getCurrentTime();
        const offset = get_offset(video);
        console.debug(video.props.prefix.replace(/.*_/, ""), {currTime, offset});
        return currTime + offset;
    }
    const seekAdjusted = (video, seekAmount) => video.seekTo(seekAmount - get_offset(video));
    const main = videos.length && videos[0].current;
    if (main && main.getDuration() < videoSyncFreq / 10) {
        videoSyncTimer = setTimeout(videoSync, videoSyncFreq * 50);
        return;
    }
    if (main && !main.paused && !seeking) {
        videos.filter((v, i) => !(!v) && i > 0).forEach(v => {
            if (seeking) {
                return;
            }
            const current = v.current;
            console.debug(current.prefix);
            if (current) {
                const delta = getAdjustedTime(main) - getAdjustedTime(current);
                const absDelta = Math.abs(delta);
                // This has been disabled, it does not work with vod
                // if (absDelta > 5 * threshold) {
                //     console.debug(new Date().toISOString(), "Delta", delta, "exceeds 5x threshold", threshold, current.props.prefix);
                //     seekAll(main.getCurrentTime());
                // } else 
                if (absDelta > threshold && !current.player.seeking) {
                    outofsync = true;
                    console.debug(new Date().toISOString(), "Delta", delta, "exceeds threshold", threshold, current.props.prefix);
                    // add half a delta since the current time is no longer valid
                    seekAdjusted(current, getAdjustedTime(main) + (delta > 0 ? 0.5 * delta : 0));
                } else if (current.player.seeking) {
                    outofsync = true;
                    timeout_factor *= 2;
                    console.debug(new Date().toISOString(), "currently seeking on video", current.props.prefix, "awaiting previous seek to finish");
                }
            }
        });
        setSyncHandle(outofsync);
    }
    videoSyncTimer = setTimeout(videoSync, videoSyncFreq * timeout_factor);
};

const initVideoSync = () => {
    videoSyncTimer = setTimeout(videoSync, videoSyncFreq * 50);
}

const initLiveVideoSync = () => {
    videoSyncTimer = setTimeout(liveVideoSync, videoSyncFreq * 50);
}

const videoStats = [];
let ended;

const measure = () => {

    videos.forEach((v, i) => {
        const video = v.current;
        if (video) {
            const time = video.getCurrentTime();
            videoStats[i] = videoStats[i] || [];
            videoStats[i].push([performance.now(), time]);
            ended = time === video.getDuration();
        }
    });
    if (!ended) {
        setTimeout(measure, 1000);
    } else {
        renderStats(videoStats);
    }
};

//measure();

const renderStats = (videoStats) => {
    const minX = Math.min(...videoStats.flatMap(stats => stats.map(st => st[0])));
    const maxX = Math.max(...videoStats.flatMap(stats => stats.map(st => st[0])));
    const maxY = Math.max(...videoStats.flatMap(stats => stats.map(st => st[1])));
    const canvas = document.createElement("canvas");
    canvas.height = 1000;
    canvas.width = 1000;
    canvas.style = "width: 1000px; heigth: 1000px; position: fixed;" +
    " left: 50px; top: 50px; z-index: 300000; border: solid thin red;" +
    "background: whitesmoke;";
    document.body.appendChild(canvas);

    const xfactor = 1000 / (maxX - minX);
    const yfactor = 1000 / maxY;
    console.log(minX, maxX);
    const colors = ['red', 'green', 'blue', 'black', 'brown'];
    const ctx = canvas.getContext('2d');
    console.log(ctx.width, ctx.height);
    videoStats.forEach((stat, i) => {
        ctx.strokeStyle = colors[i];
        const paths = stat.map(([x, y]) => [ x * xfactor, 1000 - y * yfactor]);
        console.log(paths);
        ctx.beginPath();
        paths.forEach(([x,y], i) => {
            const method = i === 0 ? ctx.moveTo : ctx.lineTo;
            method.call(ctx, x, y);
        });
        ctx.stroke();
        ctx.closePath();
    })
}

const main_first = (a, b) => (a === "main") ? -1
    : ((b === "main") ? 1 : a.localeCompare(b));

const updateCameraStatus = (performance_id, cameras) => {
    const cameraStati = cameras
        .map(camera => fetch(`/performances/${performance_id}/${camera}/status.json`)
        .then(resp => resp.json())
        .then((status) => [camera, status])
        .catch(() => [camera, {active: true}])
        );
    return Promise.all(cameraStati).then(resultEntries => Object.fromEntries(resultEntries));
    };



export const getTicketUrl = (event_id, ticket, token) => {
    // if (token === undefined ) {
    //     console.trace("Token is not present");
    //     throw new Error("undefined token");
    // }
    const url = `${base_config.server}&eventId=${event_id}&ticketNumber=${ticket}${token ? '&eventToken=' + encodeURIComponent(token) : ''}`;
    return url;
};

export const InConcert = (performance) => {

    // const [ count, setCount] = useState(0);
    // const [ countInTimeout, setCountInTimeout] = useState(0);
    const [ hidden, setHidden] = useState(false);
    const [ bufferedTracks, setBufferedTracks ] = useState([]);
    const [ syncing, setSyncing ] = useState(false);
    const [ userPaused, setUserPaused ] = useState(false);
    const [ videoDimensions, setVideoDimensions ] = useState();
    const [ availableSource, setAvailableSource] = useState({});
    const [ rotation, setRotation ] = useState({});
    const containerRef = useRef();
    const [ isFullScreen, setFullScreen ] = useState(false);
    const [moving, setMoving] = useState(false);

    const onMove = () => {
        if (moveTimer) {
            clearTimeout(moveTimer);
        }
        console.log("Moving", "onMove");
        moveTimer = setTimeout(setMoving.bind(null, false), 1000 * 3);
        setMoving(true);
    };

    const get_media = (id, all_media, device, server) => {
        let media = Object.entries(all_media).sort(([a], [b]) => main_first(a, b))
            .map(([variant, uri]) => Object.assign({
                variant,
                url: `/performances/${id}/${uri[device]}`
            },
                base_config,
                { server }));
        return media;
    };

    const media = get_media(performance.id, performance.media, base_config.device, getTicketUrl(performance.eventId, performance.ticket, performance.token));

    useEffect(() => {
        clearTimeout(videoSyncTimer);
        if (performance.live) {
            const timerUp = async() => await updateCameraStatus(performance.id, Object.keys(performance.media)).then(setAvailableSource);
            let timer;
            const interval = () => {
                timer = setTimeout(
                () => {
                    timerUp();
                    interval();
                },
                1000
            )};

            interval();
            initLiveVideoSync(performance.live);
            return () => clearTimeout(timer);
        } else {
            for (const prop of Object.getOwnPropertyNames(vod_sync_offsets)) {
                delete vod_sync_offsets[prop];
            }
            if (performance.liveRecording) {
                // live recording needs cam offsets for accurate syncing

                Promise.all(media.map(({variant, url}) => fetch(url, {method: "HEAD"}).then((response) => [variant, Date.parse(response.headers.get('last-modified')) / 1000]))).then(
                    (entries) => {
                        console.debug("sync", entries.map(e => e[1]));
                        const min_time = Math.min(...entries.map(e => e[1]));
                        const sync_offsets = Object.fromEntries(entries.map(([name, time]) => [name, time - min_time]));
                        Object.assign(vod_sync_offsets, sync_offsets);
                    }
                  );
                  
            }
            initVideoSync();
        }
    }, [performance.live]);


    setSyncHandle = (sync) => syncing !== sync && setSyncing(sync);

    const [mainMediaId, setMainMediaId] = useState('main');

    const buffering = bufferedTracks.length > 0;

    const onClick = (index) => {
        onMove();
        console.log("index", index);
        if (!userPaused && buffering) {
            if (mainMediaId !== 0) {
                // iff buffering and the current video is not main, pause it
                submitVideoOp((v, i) => i === mainMediaId, selfPause);
            }
            if (index !== 0) {
                // iff buffring and the new video is not main, resume it
                submitVideoOp((v, i) => i === index, resume);
            }
        }
        setMainMediaId(index);
    };

    const { mainKeys } = useKeyboardShortcut(
        ["Control", "0"],
        shortcutKeys => onClick("main"),
        { 
          overrideSystem: true,
          ignoreInputFields: false, 
          repeatOnHold: false 
        }
      );

      const { cam1Keys } = useKeyboardShortcut(
        ["Control", "1"],
        shortcutKeys => onClick("cam1"),
        { 
          overrideSystem: true,
          ignoreInputFields: false, 
          repeatOnHold: false 
        }
      );

    const isMain = (v, index) => v.current && (index === 0 || index === mainMediaId);
    const isNonMain = (...args) => isMain(...args) === false;

    const addVideoOp = promise_chain();

    const _selfCall = (methodName) => {
        const selfMethodName = toSelfMethodName(methodName);
        return (v) => {
            console.debug(v.current.props.prefix, methodName);
            return v.current[selfMethodName] ? v.current[selfMethodName]() : v.current[methodName]();
        }
    };

    const selfPlay = _selfCall('play');
    const selfPause = _selfCall('play');

    const resume = v => {
        const mainVideo = videos[0].current;
        let resp;
        if (v.current) {
            resp = selfPlay(v).then(() => v.current.seekTo(mainVideo.getCurrentTime()));
        }
        return Promise.resolve(resp);
    };

    const submitVideoOp = (filter, videoOperation) => addVideoOp(() => Promise.allSettled(videos.filter(filter).map(videoOperation)));
    const pauseNonMain = () => submitVideoOp(isNonMain, selfPause);

    const resumeNonMain = () => !userPaused && submitVideoOp(isNonMain, resume);

    const userPlayPause = (pause) => {
        console.log("userPlayPause", pause);

        submitVideoOp(() => true, pause ? selfPause : selfPlay);
        setUserPaused(pause);
    }

    const onBuffer = (index) => {
        console.log("buffer", buffering, bufferedTracks,  index);

        if (!buffering) {
            pauseNonMain();
        }
        if (!bufferedTracks.includes(index)) {
            setBufferedTracks(bufferedTracks.concat([index]));
        }
    }

    const onBufferEnd = (index) => {
        // console.log("bufferEnd", bufferedTracks, index);
        const newBufferedTracks = bufferedTracks.filter(i => i === index);
        if (buffering && newBufferedTracks.length === 0) {
            resumeNonMain();
        }
        setBufferedTracks(newBufferedTracks);
    };

    const toggleFullScreen = () => {
        try {
            if (screenfull.isEnabled) {
                if (screenfull.isFullscreen) {
                    screenfull.exit().then(() => setFullScreen(false));
                } else {
                    screenfull.request(containerRef.current).then(() => setFullScreen(true));
                }
            } else {
                // iphone: use native fullscreen
                const video = containerRef.current.querySelector(".playercontainer.main video");
                if (video.webkitEnterFullScreen) {
                    video.webkitEnterFullScreen();
                } else {
                    console.warn("Unsupported fullscreen request");
                }
            }
        } catch(err) {
            console.error(err);
        }
      }

    screenfull.isEnabled??screenfull.onchange(() => setFullScreen(screenfull.isFullscreen))

    useLayoutEffect(() => {
        const video = document.querySelector("video");
        let listener;
        if (video) {
            listener = (evt) => setVideoDimensions({
                '--video-height': evt.target.videoHeight,
                '--video-width': evt.target.videoWidth,
            });
            video.addEventListener("loadeddata", listener);
            console.log(video.videoHeight, video.videoWidth);
        }
        return () => { video && video.removeEventListener("loadeddata", listener)};
    });

    const getOrientation = videoDimensions => {
        if (videoDimensions) {
            return parseInt(videoDimensions['--video-height']) > parseInt(videoDimensions['--video-width']) ? 'vertical' : 'horizontal';
        }
        return "";
    };

    const all_cams_inactive = () => !Object.values(availableSource).find(status => status.active);

    const filterAvailable = (media) => {
        if (!performance.live || availableSource[media.variant]?.active) {
            return [ media ];
        } else {
            if (media.variant === 'main' && all_cams_inactive()) {
                // return wait version
                return [ Object.assign({}, media, {
                    url: "/performances/await.mp4",
                    loop: true,
                    muted: false
                }) ];
            } else {
                // nothing
                return [];
            }
        }
    };

    const computeRotation = ({referenceOrientation, orientation}) => (-referenceOrientation + (orientation - referenceOrientation)) || 0;

    window.setRotation = (deg) => {
        let rotation = document.querySelector("#root");
 
        rotation.style.cssText = `--main-video-rotation: ${deg}deg;`;
        return `${JSON.stringify(availableSource['main'])} => ${deg}`;
    }
    const activeMedia = media.flatMap(filterAvailable);
    const activeMainMediaId = (activeMedia.find(media => media.variant === mainMediaId) || activeMedia[0]).variant;
    const getRotation = () => {
        return rotation[activeMainMediaId] || 0;
    }

    const updateRotation = () => setRotation(rotation => Object.assign({}, rotation, {[activeMainMediaId]: (((rotation[activeMainMediaId] || 0) + 90) % 360)}));
    //'--main-video-rotation': `${computeRotation(availableSource['main'] || {})}deg`
    return (<div className="inconcert"><div className={`inconcert-view ${getOrientation(videoDimensions)}`} ref={containerRef} style={Object.assign({
        '--streams-count': media.length, '--main-video-rotation': `${getRotation()}deg`}, videoDimensions)}>
        {/* <button className="btn-right" onClick={() => onBuffer(6)}>Trigger buffering</button> */}
        <button key="rotate" className="btn-right rotate" onClick={updateRotation}><FontAwesomeIcon  key="rotate" icon={faRedo} /></button>
        {media.length > 1 ? (<button key="expand" className="btn-right" onClick={() => setHidden(!hidden)}><FontAwesomeIcon  key="expand" icon={faThLarge} /></button>) : (null)}
        <div className="indicator">{syncing ? (<FontAwesomeIcon key="sync" icon={faSyncAlt} className="fa-spin" />) : null}</div>
        {activeMedia.map((media, index, {length}) => (<ConcertPlayer
            key={media.variant}
            prefix={"inconcert_" + media.variant.replace('-', '')}
            muted={index !== 0}
            index={index}
            singleStream={length === 1}
            buffering={buffering}
            onBuffer={() => onBuffer(index)}
            onBufferEnd={() => onBufferEnd(index)}
            syncing={syncing}
            userPlayPause={userPlayPause}
            showControls={!performance.live}
            moving={moving}
            boxart={performance.boxart ? `/performances/${performance.id}/${performance.boxart}` : ''}
            onFullScreen={toggleFullScreen}
            {...media} onClick={() => onClick(media.variant)} isHidden={hidden} isMain={media.variant === activeMainMediaId} isFullScreen={isFullScreen}/>))}
    </div>

    </div>);
}

let launched;
const ConcertPlayer = ({ isHidden, isMain, url, server, cert, muted, variant, prefix, onClick, index, buffering, moving, 
    onBuffer, onBufferEnd, userPlayPause, syncing, singleStream, boxart, loop, showControls,onFullScreen,isFullScreen }) => {
    const [duration, setDuration] = useState();
    const [progress, setProgress] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [pip, setPip] = useState(false);
    const [volume, setVolume] = useState();
    const [playing, setPlaying] = useState(false);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [hasLaunched, setHasLaunched] = useState();
    const [ended, setEnded] = useState(false);

    const player = useRef(null);
    videos[index] = player;
    if (player.current && hasLaunched !== undefined) {
        window.plainPlayer = player.current;
        window.thisPlayer = Object.assign(
            Object.create(UnwrappedInplayPlayer.prototype), 
            player.current, 
            { 
                seekTo: (s) => setTimeout(() => seekAll(s), 0),
                play: () => Promise.all(videos.map(v => v.current.play())),
                pause: () => Promise.all(videos.map(v => v.current.pause())),
            }
            , Object.fromEntries(['getVolume', 'setVolume', 'isMuted', 'unmute', 'mute'].map(
                (method) => {
                    let bindMethod = videos[0]&&videos[0].current ? (UnwrappedInplayPlayer.prototype[method] || videos[0].current[method]) : (UnwrappedInplayPlayer.prototype[method] ?UnwrappedInplayPlayer.prototype[method]:{});
                    return [method, bindMethod.bind(videos[0].current)]
                })
            )
        );
    }
    useLayoutEffect(() => {
        if (hasLaunched === undefined && player.current) {
            console.log("adding can play");
            const onCanPlay = () => player.current.player.play().then(() => { 
                player.current.player.removeEventListener("canplay", onCanPlay);
                console.log("auto play is starting"); 
                let launched = true;
                setHasLaunched(true); 
                Promise.all(videos.map(v => v.current.play())) 
            }, () => { 
                player.current.player.removeEventListener("canplay", onCanPlay);
                setHasLaunched(false); 
                console.log("auto play not possible");
            });
            player.current.player.addEventListener("canplay", onCanPlay);
            // safari is canplay event is unreliable, let's do a workaround with timer
            const timer = setTimeout(() => {
                if (player.current.player.paused && hasLaunched === undefined) {
                    setHasLaunched(false);
                }
            }, 3000);
            console.log("canplay added");
            return () => { clearTimeout(timer)}
        }
    }, [ isMain, hasLaunched, player, setHasLaunched]);
    let launcher =  null;
    if (hasLaunched === false && !launched && isMain) {
        launcher = (<div className={`ticket-check `} onAnimationEnd={() => {}}>
                <p>Click the 'Start' button to begin your inconcert experience</p>
                <button onClick={() => { setPlaying(true); setHasLaunched(true); Promise.all(videos.map(v => v.current.play())); } }>Start</button>
            </div>);
        
    }

    return [launcher, isMain && !isHidden && !singleStream ? (<PlayerPlaceHolder label={variant} />) : null, (
        <PlayerContainer key={variant} style={{ visibility: (!isMain && isHidden) ? "hidden" : "visible", zIndex: isMain ? 2 : isHidden ? 0 : 3}}
            onClick={onClick} timeout="3" className={`playercontainer ${isMain ? 'main' : ''} ${buffering > 0 ? 'buffering' : ''}`} prefix={prefix} fullScreenHandler={{"event":onFullScreen,"status":isFullScreen}}> 
            <span className="label">{variant}</span>
            <UnwrappedInplayPlayer
                ref={player}
                prefix={prefix}
                className="InplayPlayer"
                poster={boxart || 'inplaylogo.png'}
                width="100%"
                height="auto"
                url={url}
                resumptionRetention={0}
                abr={isMain ? 'hi' : 'lo'}
                // abr={isMain && !url.endsWith('.m3u8') ? 'auto' : 'lo'}
                server={server}
                cert={cert}
                deviceValidation={false}
                customData={[{ name: "MAC", value: "00-00-00-00" }]}
                isAudio={false}
                playing={playing}
                onSeek={() => { }}
                onBuffer={loaded ? onBuffer : () => {}}
                onBufferEnd={onBufferEnd}
                onProgress={setProgress}
                onReady={() => setLoaded(true)}
                onStart={() => console.debug("onStart")}
                onPlay={() => setPlaying(true)}
                onEnablePIP={() => setPip(true)}
                onDisablePIP={() => setPip(false)}
                onPause={() => setPlaying(false)}
                onVolume={() => { }}
                onError={err => console.debug("error args", err, err.detail)}
                onDuration={setDuration}
                controls={false}
                loop={loop}
                playbackRate={1}
                bufferingGoal={6}
                rebufferingGoal={3}
                muted={muted}
                playsinline={true}
                adActive={false}
                onEnded={() => {setEnded(true);}}
                videoWidth={isMain ? 1920 : 240}
                videoHeight={isMain ? 1080 : 144}
            />
            {isMain && moving && hasLaunched !== undefined && window.thisPlayer && loaded && showControls? (
                <>
                    <GiantButtons player={window.thisPlayer} />
                    <BottomControl player={window.thisPlayer} />
                </>) : (null)
            }
            {isMain && ended ?
                (<div className='end-fade'>
                    <p>Thank you for watching this content provided to you by Inconcert!</p>
                    <button onClick={()=>{window.location.href='/'}}>OK</button>
                </div>) : (null)
            }
        </PlayerContainer>)];
}

const toSelfMethodName = methodName => `self${methodName.charAt(0).toUpperCase() + methodName.substring(1)}`;
// create a version where a method, e.g. play, seekTo etc., will apply to all videos
const addAllVideoControls = (player, userPlayPause) => {
    return methodName => {
        const selfMethodName = toSelfMethodName(methodName)
        // store individual method under a different name
        player[selfMethodName] = player[methodName];
        // replace main player with a method that seeks all videos
        if (methodName !== 'play' && methodName !== 'pause') {
            player[methodName] = (...args) =>
            Promise.all(videos.map(v => {
                const currentInplayer = v.current;
                if (currentInplayer) {
                    // if main player, call the original method
                    // only main player has the 'thisMethod', so this works
                    const method = currentInplayer[selfMethodName] || currentInplayer[methodName];
                    return method.apply(currentInplayer, args);
                }
            }));
        } else {
            // if method play or pause, make it a videoOp
            player[methodName] = userPlayPause.bind(undefined,  methodName === 'pause');
        }
    }
}

const removeAllVideoControls = (player) => {
    return methodName => {
        const selfMethodName = toSelfMethodName(methodName)
        // restore individual method under a original name
        player[methodName] = player[selfMethodName];
        // delete self method 
        delete player[selfMethodName];
    }
}


const PlayerPlaceHolder = ({ label }) => {
    return (
        <div className="playercontainer placeholder" style={{ display: 'block' }}>
            <span className="label">{`${label} \u25CF`}</span>
        </div>);
}

