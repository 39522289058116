import React, { useState, useEffect, createContext, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import IconContainer from '../container/IconContainer.js'
import { to_ranges, isIE, isEdge, isFF } from '../utils/Range';

import './BottomControl.scss';
import './InputRange.scss';
import spin from '../Icon/spin.svg';
import report from '../Icon/report.svg';
import FullScreen_svg from '../Icon/expand-wide.svg';
import NormalScreen_svg from '../Icon/compress-wide.svg';
import VolumeOff_svg from '../Icon/volume-mute.svg';
import VolumeUp_svg from '../Icon/volume-up.svg';
import PicInPic_svg from '../Icon/images.svg';
import Slowest_svg from '../Icon/tachometer-alt-slowest.svg';
import Slow_svg from '../Icon/tachometer-alt-slow.svg';
import Average_svg from '../Icon/tachometer-alt-average.svg';
import Fast_svg from '../Icon/tachometer-alt-fast.svg';
import Fastest_svg from '../Icon/tachometer-alt-fastest.svg';
import Ellipsis_svg from '../Icon/ellipsis-v.svg';

import { withSimpleModal } from '../utils/SimpleModal';
import { Settings } from '../Settings/Settings';
import { SceneSeek } from '../SceneSeek/SceneSeek';
import { PlayerContext } from '../container/PlayerContainer.js';
import PopoverStickOnHover from './PopoverStickOnHover.js'
import { configureIcons, ToComp } from '../Icon/support';

import Duration from './Duration.js';
import config from '../../../config';
import { emitInshowEvent, Events, useCurrentTime } from 'inplayweb6-lib';

const isIpadOS = () => {
    return navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform);
  }



let play_arrow = config.icons.play_arrow;
let Accessibility_svg = config.icons.Accessibility_svg;

// let FullScreen = ToComp.bind(null, FullScreen_svg);
// let NormalScreen = ToComp.bind(null, NormalScreen_svg);
let MobileSetting = ToComp.bind(null, Ellipsis_svg);
let VolumeOff = ToComp.bind(null, VolumeOff_svg);
let VolumeUp = ToComp.bind(null, VolumeUp_svg);
// let PicInPic = ToComp.bind(null, PicInPic_svg);
// let Accessibility = Accessibility_svg;
// let Slowest = ToComp.bind(null, Slowest_svg);
// let Slow = ToComp.bind(null, Slow_svg);
// let Average = ToComp.bind(null, Average_svg);
// let Fast = ToComp.bind(null, Fast_svg);
// let Fastest = ToComp.bind(null, Fastest_svg);

const Icons = {
    pip: ToComp.bind(null, PicInPic_svg),
    accessibility: Accessibility_svg,
    playbackSpeed: {
        quarter: ToComp.bind(null, Slowest_svg),
        half: ToComp.bind(null, Slow_svg),
        normal: ToComp.bind(null, Average_svg),
        double: ToComp.bind(null, Fast_svg),
        triple:  ToComp.bind(null, Fastest_svg)
    },
    fullscreen: {
        full: ToComp.bind(null, FullScreen_svg),
        normal: ToComp.bind(null, NormalScreen_svg)
    },
    volume: {
        off: ToComp.bind(null, VolumeOff_svg),
        up: ToComp.bind(null, VolumeUp_svg)
    }
}



const SettingsDialog = withSimpleModal(Settings);

const volumeIcons = [{
    label: "VolumeOff",
    icon: "off"
}, {
    label: "VolumeUp",
    icon: "up"
}
];

const trickPlayIcons = [{
    rate: 0.25,
    icon: "quarter"
}, {
    rate: 0.5,
    icon: "half"
}, {
    rate: 1,
    icon: "normal"
}, {
    rate: 2,
    icon: "double"
}, {
    rate: 3,
    icon: "triple"
}
];

const partition = (arr, fn) => arr.reduce((partitioned, item) => {
    partitioned[fn(item) ? 1 : 0].push(item);
    return partitioned;
}, [[], []]);

const split_to_lower_and_upper = (ranges, midpoint) => {
    // prepare lower and upper half partitions of the ranges
    const [lower, upper] = partition(ranges.flatMap(item => item.from < midpoint && item.to > midpoint ?
        // this item spans midpoint, split into two
        [{ ...item, ...{ to: midpoint } }, { ...item, ...{ from: midpoint } }] : [item]), ({ to }) => to > midpoint);

    const rs = (lo, hi, val) => 100 * (val - lo) / (hi - lo);
    const rescale = (lo, hi, { from, to, color }) => {
        return { color, from: rs(lo, hi, from), to: rs(lo, hi, to) };
    };

    // rescale lower(0 to midpoint) and upper (midpoint to 100) ranges they each amount to 100% 
    return [lower.map(rescale.bind(null, 0, midpoint)), upper.map(rescale.bind(null, midpoint, 100))];
};

const to_linear_gradient = ranges => `linear-gradient(to right, ${ranges.map(({ from, to, color }) => `${color} ${from}% ${to}%`).join(', ')})`;

export default function BottomControl({
    error,
    paused,
    muted,
    player,
    loading,
    onFullscreenClick,
    children,
    adPoints,
    nominalSceneWidth,
    language,
    configuration
}) {
    const { dimensions, moving, toggleFullScreen, isHamburgerMenuOn, toggleMobileSetting, containerRef, requestSuspend, requestResume, setKeepActive, supportsHover, isFullScreen } = useContext(PlayerContext);
    const iconProps = {
        className: 'icon',
        height: 26,
        width: 26,
        fill: '#FFFFFF'
    };
    const [currentTrickPlayRate, setCurrentTrickPlayRate] = React.useState(2);
    const [current, setCurrent] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    if (configuration) {
        configureIcons(Icons, configuration);
    }

    const toggleOpen = (state) => {
        const new_open = state !== undefined ? state : !open;
        setOpen(new_open);
        setKeepActive(new_open);
        (new_open ? requestSuspend : requestResume)(player);
    };
    const [seekPos, setSeekPos] = useState();
    const [hoverSeekBar, setHoverSeekBar] = useState(false);
    if (error) {
        return (
            <span className="inner1">
                <img src={report} {...iconProps} />
            </span>
        );
    } else if (loading) {
        return (
            <span className="inner1">
                <img src={spin} {...iconProps} />
            </span>
        );
    } else if (paused) {
        return (
            <span className="inner1">
                <img src={play_arrow} {...iconProps} />
            </span>
        );
    }

    // do nothing, will be overridden by sceneseek
    let moveHandler;
    let seekBarHideTimer;
    const onMove = ({ clientX, touches }) => {
        setHoverSeekBar(true);
        if (moveHandler) {
            moveHandler(clientX || touches[0].clientX);
        }
    };

    const currentTime = useCurrentTime(player);

    useEffect(() => {
        return () => clearTimeout(seekBarHideTimer);
    });

    

    const hoverListerners = {};

    // on iOS hover listeners causes click events requiring multiple clicks
    if (supportsHover) {
        const onMouseOver = () => {
            setHoverSeekBar(true);
        };
        const onMouseOut = () => {
            setHoverSeekBar(false)

        };
        Object.assign(hoverListerners, {onMouseOver, onMouseOut});
    } else {
        const onTouchStart = () => {
            setHoverSeekBar(true);
        };
        const onTouchEnd = () => {
            setHoverSeekBar(false)

        };
        const onTouchCancel = () => {
            setHoverSeekBar(false)

        };
        Object.assign(hoverListerners, {onTouchStart, onTouchEnd, onTouchCancel});

    }

    const CurrentComp = Icons.volume[volumeIcons[current].icon];
    const playback = player ? player.getCurrentTime() / player.getDuration() * 100 : 0;
    const buffer = player ? player.getBufferedTime() / player.getDuration() * 100 : 0;
    const ranges = to_ranges(playback, buffer, (adPoints || []).map(pt => 100 * pt / player.getDuration()));
    const [lower_ranges, upper_ranges] = split_to_lower_and_upper(ranges, playback);
    const FullScreenToggle = isFullScreen ? Icons.fullscreen.normal : Icons.fullscreen.full;
    
    const handleVolume = () => {
        current === 1 ? setCurrent(0) : setCurrent(1);
        if (current === 1) {
            setCurrent(0);
            emitInshowEvent(Events.Muted);
        } else {
            emitInshowEvent(Events.UnMuted);
        }
      }

    return player ? (
        <div className={"component " + (moving ? 'show' : 'hidden')}          
        >
            <div className="inner">
                <PopoverStickOnHover
                    component={<div>
                        <input
                            id="volume_bar" aria-label="volume bar"
                            className="volume-bar" step="any" orient="vertical"
                            type='range' min={0} max={1}
                            value={player.getVolume()}
                            onChange={({ target }) => player.setVolume(target.value)} />
                    </div>}
                    container={containerRef.current}
                    placement="top"
                    onMouseEnter={() => { }}
                    delay={200}
                >
                    <div className={(isMobile || isIpadOS() ? 'hide-icon' : 'show-icon')}>
                        <CurrentComp id="mute_button" aria-label="mute button"
                            variant="success" onClick={() => { player.isMuted() ? player.unmute() : player.mute(); handleVolume(); }}
                            {...iconProps} />
                    </div>
                </PopoverStickOnHover>
                <Duration id={'current_time'} ariaLabel={'current time'}
                    className={'duration'} seconds={currentTime} />
                <style dangerouslySetInnerHTML={{
                    __html: `
                    input[type='range']::-ms-fill-lower {
                        background-image: ${to_linear_gradient(lower_ranges)} !important;
                    }
                    input[type='range']::-ms-fill-upper {
                        background-image: ${to_linear_gradient(upper_ranges)} !important;
                    }
                    input[type='range']::-moz-range-track {
                        background: ${to_linear_gradient(ranges)} !important;
                    }
                `}}></style>
                <input
                    id="seek_bar" aria-label="seek bar"
                    className="seek-bar"
                    style={(isIE || isEdge || isFF) ? {} : { background: to_linear_gradient(ranges) }}
                    type='range' min={0} max={player.getDuration()} step='any'
                    value={currentTime}
                    onChange={({ target }) => player.seekTo(target.value)}
                    onMouseMove={onMove}
                    onTouchMove={onMove}
                    onMouseDown={() => { emitInshowEvent(Events.StartSeek); }}
                    onMouseUp={() => { emitInshowEvent(Events.ExitSeek); }}
                    {...hoverListerners}
                />
                <SceneSeek id="scene_seek" aria-label="scene seek" player={player} seekSource={handler => moveHandler = handler} isHidden={!hoverSeekBar} nominalSceneWidth={nominalSceneWidth}/>
                <Duration id={'duration'} ariaLabel={'duration'} className={'duration'} seconds={player.getDuration()} />
                <IconContainer id="icon_container" aria-label="Icon Container" className={isHamburgerMenuOn ? 'iconContainer' : 'iconContainer iconContainerDisplay'}>
                    {children}
                    <Icons.pip id="pic_in_pic" role="button" aria-label="picture in picture button" className={player.canEnablePIP() ? '' : 'icon btn-disable'} onClick={() => player.enablePIP()}
                        {...iconProps} />
                    {trickPlayIcons.map(i => Object.assign(i, {comp: Icons.playbackSpeed[i.icon]})).map((Icon, i) => 
                    (<Icon.comp key={i} id={"trickplay_button_" + i} role="button" aria-label="trick play button" onClick={() => {
                        const newCurrentTrickPlayRate = (i + 1) % trickPlayIcons.length;
                        setCurrentTrickPlayRate(newCurrentTrickPlayRate);
                        player.setPlaybackRate(trickPlayIcons[newCurrentTrickPlayRate].rate);
                    }} style={{display:  i === currentTrickPlayRate ? "" : "none"}} {...iconProps} />))}

                    <Icons.accessibility id="accessibility_button" role="button" aria-label="accessibility button"
                        onClick={() => { console.log("toggle open begin"); toggleOpen();emitInshowEvent(Events.SelectedAccessibility); console.log("toggle open end");  }} {...iconProps} />
                    <SettingsDialog
                        role="button"
                        modalTitle=""
                        visible={open}
                        player={player}
                        language={language}
                        dialogClassName="Settings-dialog"
                        modalProps={{ container: containerRef.current }}
                        onclose={() => { toggleOpen(false); }} />
                </IconContainer>
                <FullScreenToggle id="fullscreen_button" aria-label="fullscreen button" onClick={()=> {toggleFullScreen(); isFullScreen ?emitInshowEvent(Events.ExitFullScreen): emitInshowEvent(Events.EnterFullScreen);}} {...iconProps} />
                <MobileSetting id="mobile_setting_button" aria-label="mobile setting button" onClick={toggleMobileSetting} {...{...iconProps, ...{className: "icon mobile-setting"}}} />
            </div>
        </div>
    ) : null;
};
