import React, { Fragment, Component, useState, useEffect, useContext } from 'react';

import "./Settings.scss";
import { getAccessibilityManager } from '../Accessibility/Accessibility';
import { translate, get_language_font_size } from "../Accessibility/translations";
import { emitInshowEvent, Events } from 'inplayweb6-lib';
import { PlayerContext } from '../container/PlayerContainer.js';

let current_language;
let translate_current;

const isMobilePortrait = window.matchMedia("only screen and (max-width: 767px) and (orientation: portrait)").matches;

const [SettingContainer, SettingItem] = isMobilePortrait ?
    ["select", "option"] : ["ul", "li"];

const toLabel = item => isMobilePortrait && item && item.props && item.props.children ?
    (Array.isArray(item.props.children) ? item.props.children : [item.props.children]).map(toLabel).join(": ")
    : item;

const toId = item => isMobilePortrait && item && item.props && item.props.children ?
    (Array.isArray(item.props.children) ? item.props.children : [item.props.children]).map(toLabel).join(": ")
    : item;

const Setting = ({ header, items, RenderLabel, isActive, activeIndex, onClick }) => (
    <div className="setting" style={{ fontSize: get_language_font_size(current_language) }}>
        <span>{translate_current(header)}</span>
        <SettingContainer onChange={({ target }) => onClick(items[target.selectedIndex], target.selectedIndex)}>
            {items.map((item, i) => (
                <SettingItem key={i} id={toId(RenderLabel({ item }, true))} aria-label={toId(RenderLabel({ item }, true))} className={`setting-item ${isActive(item, i) ? 'active' : ''}`}
                    selected={activeIndex !== undefined ? i === activeIndex : isActive(item, i)}
                    onClick={() => onClick(item, i)}>{toLabel(RenderLabel({ item }))}</SettingItem>
            ))}
        </SettingContainer>
    </div>);

const AudioAndSubtitles = ({ player, settings }) => {
    const [languages, setLanguages] = useState();
    const { languageConfig } = useContext(PlayerContext);
    console.debug("languageConfig is: ", languageConfig);
    let langLoader;
    if (languageConfig) {
        langLoader = () => Promise.resolve({
            json: () => languageConfig
        });
    }
    useEffect(() => {
        const fetchData = async () => {
            (langLoader ? langLoader() : fetch('languages.json'))
                .then((response) => {
                    return response.json();
                })
                .then((languages) => {
                    setLanguages(languages);
                }).catch(err => {
                    console.warn("languages.json can't be loaded");
                    setLanguages({});
                });
        };
        fetchData();
    }, []);

    const findActiveIndex = tracks => tracks.findIndex(t => t.active);
    const textTracks = player.getTextTracks()
        .concat({
            type: "text",
            language: "Off",
            active: findActiveIndex(player.getTextTracks()) === -1
        });
    const audioTracks = player.getVariantTracks("audio").filter(t => t.language);

    const [activeAudioIndex, setActiveAudioIndex] = useState(findActiveIndex(audioTracks));
    const [activeTextIndex, setActiveTextIndex] = useState(findActiveIndex(textTracks));

    const onClick = (track, i) => {
        if (track.type === "text") {
            setActiveTextIndex(i);
            emitInshowEvent(Events.PickSubLang, { "Language": track.language });
        } else {
            setActiveAudioIndex(i);
            emitInshowEvent(Events.PickAudioLang, { "Language": track.language });
        }
        player.setCurrentTrackId(track.type, track.id);
    };
    const renderLabel = ({ item }, text) => text ? translate_current(languages[item.language] || item.language) :
        (<>{translate_current(languages[item.language] || item.language)}
            {(item.roles || []).find(role => ['caption', 'captions'].includes(role)) ? (<span className='cc'></span>) : null}
        </>);
    const isActive = item => item.active;
    return languages ? (
        <div className="panel">
            <Setting header="audio" items={audioTracks}
                RenderLabel={renderLabel}
                onClick={onClick}
                activeIndex={activeAudioIndex}
                isActive={isActive} />
            <Setting header="subtitles" items={textTracks}
                RenderLabel={renderLabel}
                onClick={onClick}
                activeIndex={activeTextIndex}
                isActive={isActive} />
        </div>) : (null);
};

const Accessibility = () => {
    const [accMgr, setAccManager] = useState();
    const [detailPane, setDetailPane] = useState(0);
    const [detailValue, setDetailValue] = useState();
    const { accessibilityStyleManager, accessibilityConfig } = useContext(PlayerContext);

    console.debug("accessibilityStyleManager is: ", accessibilityStyleManager);
    console.debug("accessibilityConfig is: ", accessibilityConfig);

    useEffect(() => {
        const fetchData = async () => {
            const accMgr = await getAccessibilityManager(accessibilityStyleManager, accessibilityConfig ? () => Promise.resolve({ json: () => accessibilityConfig}) : undefined);
            setAccManager(accMgr);
        };
        fetchData();
    }, []);
    const getActiveLabel = (item) => {
        if (item.key) {
            const selectedValue = accMgr.state[item.key];
            const option = item.options.find(opt => opt.value == selectedValue) || item.options[0];
            return option.label;
        } else {
            return null;
        }
    };
    const detailItem = accMgr && accMgr.config[detailPane];
    const updateDetail = item => {
        accMgr.updateState({ [detailItem.key]: item.value });
        setDetailValue(item.value);
    };
    const restoreDefaults = () => {
        accMgr.resetState();
        setDetailValue("");
    }

    const onMainClick = (item, i) => (i == accMgr.config.length) ? restoreDefaults() : setDetailPane(i);
    const mainItems = () => isMobilePortrait ? accMgr.config : accMgr.config.concat({ label: translate_current('Restore Defaults') });
    return (accMgr ?
        <>
            <div className="main-panel panel">
                <Setting header="Setting" items={mainItems()}
                    RenderLabel={({ item }) =>
                        (<div><span>{translate_current(item.label)}</span><span>{translate_current(getActiveLabel(item))}</span></div>)}
                    onClick={onMainClick}
                    isActive={(item, i) => i == detailPane} />
            </div>
            <div className="panel">
                <Setting header={detailItem.label} items={detailItem.options}
                    RenderLabel={({ item }) => translate_current(item.label)}
                    onClick={updateDetail}
                    isActive={item => item.label == getActiveLabel(detailItem)} />
                {isMobilePortrait ? (<div className="setting"><button onClick={restoreDefaults} className="btn btn-primary">{translate_current('Restore Defaults')}</button></div>) : null}
            </div>
        </> : null);
}

const panels = [{
    label: "Audio And Subtitles",
    comp: AudioAndSubtitles
}, {
    label: "Accessibility",
    comp: Accessibility
}
];

export const Settings = (props) => {
    translate_current = translate.bind(null, current_language = props.language);
    const [current, setCurrent] = useState(0);
    const CurrentComp = panels[current].comp;
    return (<div className="panels">
        <span>
            {panels.map((panel, i) => (
                <button key={i} id={panel.label} aria-label={panel.label}
                    className={`btn btn-${i != current ? 'outline-' : ''}primary`}
                    onClick={() => setCurrent(i)}
                >{translate_current(panel.label)}</button>
            ))}
        </span>
        <div className="main">
            <CurrentComp {...props} />
        </div>
        <span className="in_captions captions-preview">{translate_current('Sample Subtitle Text')}</span>
    </div>);
};

