import React, {useEffect, useState} from 'react';
import './App.css';
import { MemoryRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Home } from './pages/Home';
import { Performance } from './pages/Performance';
import { loadPerformances } from './model/performances';
import { get_query_param } from './util/ticket_validator';

const pages = {
  '/inconcert': Home,
  '/performance/:id': Performance
};

function App() {
  const [ performances, setPerformances] = useState();
  const [ failed, setFailed ] = useState(false);
  useEffect(() => {
    const fetchPerformances = async() => {
      try {
        const performances = await loadPerformances();
        setPerformances(performances);
      } catch (err) {
        setFailed(true);
      }
    };
    fetchPerformances();
  });
  return performances ? (
    <><Router>
      <Switch>
          {Object.entries(pages).map(([path, Page]) =>(<Route path={path} render={(routeProps) => (<Page {...routeProps}/>)} key={path} />)
        )}
        <Redirect to={Object.keys(pages)[0]} />
      </Switch>
    </Router ><About/></>
   ) : (null);
}

const About = () => {
  const debug = !(!(get_query_param('debug')));
  const [items] = useState(Array.from(document.querySelectorAll('meta[name^="player"]')).map(meta => [meta.name.replace(/(player)?-/g, " ").trim(), meta.content]));
  return (debug ? <details id="about">
    <summary>About</summary>
    <ul>
      {items.map(([name, value]) => (<li key={name}><span className="name">{name}</span>: {value}</li>) )}
    </ul>
  </details>: (null))
}

export default App;

