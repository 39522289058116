/* jshint esversion: 6 */

import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from "react";
import { PlayerContext } from '../container/PlayerContainer';
import {Scenes } from './Scenes';


const absDiff = (a, b) => b > a ? b - a : a - b;

const distance = (s, e, t) => (s <= t && e >= t) ? 0 : Math.sign(s - t) * Math.min(absDiff(s, t), absDiff(e, t));

const getVttUrlMpd = (manifest) => {
    const match = manifest.match(/<Representation id="thumbnails">\s+<BaseURL>([^<]+)<\/BaseURL>/m);
    return match && match[1];
};

const getVttUrlM3u8 = (manifest) => {
    return manifest.split("\n").filter(line => line.match(/.*NAME="thumb".*/))
        .map(line => line.replace(/.*URI="([^"]+)".*/, "$1"))[0];
};

const timeParser = {
    factors: [60, 60, 1000, 1],
    regex: /(\d{2}):(\d{2}):(\d{2}).(\d{3})/,
    parseTime: (groups) => groups.reduce((acc, val, i) => (acc + parseInt(val)) * timeParser.factors[i], 0)
};

const parseThumbnailVtt = (vttText) => vttText.split(/\r?\n/).map(line =>
    // match vtt line, either time -- > time, or spritefile.jpg#xywh=$x,$y,$w,$h
    line.match(/^(?:(\d{2}):(\d{2}):(\d{2}).(\d{3}) --> (\d{2}):(\d{2}):(\d{2}).(\d{3}))|(?:([^#]+)#xywh=(\d+),(\d+),(\d+),(\d+))$/))
    // filter non-matches
    .filter(r => r)
    // parse, combine and reduce to array of thumbNail entries
    .reduce((tns, groups, i) => i % 2 == 0 ?
        tns.concat({
            startTime: timeParser.parseTime(groups.slice(1, 5)) / 1000,
            endTime: timeParser.parseTime(groups.slice(5, 9)) / 1000
        }) : tns.slice(0, -1).concat({
            ...tns[tns.length - 1], ...{
                url: groups[9],
                x: parseInt(groups[10]),
                y: parseInt(groups[11]),
                w: parseInt(groups[12]),
                h: parseInt(groups[13])
            }
        }), []);

const ensureMaxSceneLength = () => {
    let maxSceneLength;
    return (tn) => {
        const {startTime, endTime} = tn;
        const sceneLength = endTime - startTime;
        if (!maxSceneLength) {
            maxSceneLength = sceneLength;
        }
        return (sceneLength > maxSceneLength) ?
          new Array(1 + parseInt(sceneLength / maxSceneLength)).map((_, i) => Object.assign(
            tn, {
                startTime: startTime + i * sceneLength,
                endTime: Math.min(startTime + (i + 1) * sceneLength, endTime)
            }))
        : [tn];
    };
};

const getThumbNails = async (url) => {
    const expandUrl = (base_url, path) => base_url && path && base_url.replace(/[^\/]+$/, path);
    const getVttUrl = url ? (url.match(/.mpd$/) ? getVttUrlMpd : getVttUrlM3u8) : () => undefined;
    const vttUrl = await fetch(url).then(r => r.text()).then(getVttUrl).then(expandUrl.bind(null, url));
    return (vttUrl) ? fetch(vttUrl).then(r => r.text()).then(parseThumbnailVtt)
    .then(tns => tns.map(tn => Object.assign(tn, { url: expandUrl(vttUrl, tn.url) })).flatMap(ensureMaxSceneLength())) :
        Promise.resolve([]);
};

export const SceneSeek = ({ player, seekSource, isHidden, nominalSceneWidth }) => {
    const [thumbNails, setThumbnails] = useState([]);
    const { dimensions, isFullScreen } = useContext(PlayerContext);
    const sideThumbNailCount = 2;
    useEffect(() => {
        let unmounted = false;
        const fetchData = async () => {
            let thumbNails = await getThumbNails(player.getUrl());
            if (!unmounted) {
                setThumbnails(thumbNails);
            }
        }
        fetchData();
        return () => unmounted = true;
    }, [player.getUrl()]);
    const [seekBarRect, setSeekBarRect] = useState(
        { x: 0, y: 0, width: 0, height: 0, top: 0, right: 0, bottom: 0, left: 0 }
    );
    const sceneSeekContainer = useRef(null);
    const getDimensions = node => {
        let { x, y, width, height, top, right, bottom, left } = node.getBoundingClientRect();
        height += parseInt(window.getComputedStyle(node).marginTop) +
            parseInt(window.getComputedStyle(node).marginBottom);
        return { x, y, width, height, top, right, bottom, left };
    }
    useLayoutEffect(() => {
        let previousWidth;
        // The browser will modify the range slider due to automatic sizing
        // I.e. the first bounding client rect is not the same as final
        // therefore, this will run every 50 ms and update the bounding rect
        // until no changes to the width have been occurrent since the last execution
        const timer = setInterval(() => {
            if (sceneSeekContainer.current && sceneSeekContainer.current.previousSibling && sceneSeekContainer.current.offsetParent) {
                let { x, y, width, height, top, right, bottom, left } = getDimensions(sceneSeekContainer.current.previousSibling);
                // this is the closest positioned parent
                let parent = getDimensions(sceneSeekContainer.current.offsetParent);
                if (previousWidth === width) {
                    clearInterval(timer);
                }
                previousWidth = width;
                setSeekBarRect({ x, y, width, height, top, right, bottom, left: left - parent.left});
            }
        }, 50);
        return () => clearInterval(timer);
    }, [dimensions.width, dimensions.height, isFullScreen, sceneSeekContainer]);

    const duration = player.getDuration();
    const [xpos, setXPos] = useState(seekBarRect.width * player.getCurrentTime() / duration + seekBarRect.left);
    seekSource(setXPos);
    var percentage = (xpos - seekBarRect.x) / seekBarRect.width;
    if (percentage < 0) {
        percentage = 0;
    }
    if (percentage <= 1) {
        var time = percentage * duration;
    }

    const maxSceneHolderWidth = seekBarRect.width / 6;
    const durationSq = duration * duration;

    const onMove = ({ clientX }) => setXPos(clientX);
    const [hover, setHover] = useState(false);
    const onMouseLeave = () => {
        setHover(false)
    };
    const onMouseOver = () => {
        setHover(true)
    };
    const setCurrentTime = player.seekTo.bind(player);
    const shouldDisplay = hover || (!isHidden);
    return (<Scenes 
        maxSceneHolderWidth={maxSceneHolderWidth}
        dimensions={dimensions}
        duration={duration}
        left={seekBarRect.left}
        top={seekBarRect.height}
        width={seekBarRect.width}
        sceneSeekContainer={sceneSeekContainer} 
        sideThumbNailCount={sideThumbNailCount} 
        listeners={{onMouseMove: onMove, onTouchMove: onMove, onMouseLeave, onMouseOver}}
        nominalSceneWidth={nominalSceneWidth}
        thumbNails={thumbNails} shouldDisplay={shouldDisplay} time={time} setCurrentTime={setCurrentTime} seekBarRect={seekBarRect}/>); 
};
